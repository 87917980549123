import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js"

export default class extends Controller {
  static targets = [ "placeholder" ]

  show() {
    if(this.preventAction) return;

    let link = document.createElement('a');
    link.setAttribute('href', `tel:${this.data.get("part-1")}`);
    link.innerHTML = `${this.data.get("part-1")}`;
    link.setAttribute('class', 'select-all');

    this.placeholderTarget.parentNode.replaceChild(link, this.placeholderTarget);
    this.data.set("prevent-action", true);
    ahoy.track("show phone number", {advert_id: this.data.get("advert-id")});
  }

  get preventAction(){
    return this.data.get("prevent-action") === "true";
  }
}
