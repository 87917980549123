import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inputLocation", "selectedLocation", "warningMessage" ]
  static classes = [ "message"]

  clear() {
    this.selectedLocationTarget.value = ''
  }

  verify(event){
    if(this.selectedLocationTarget.value == "" && this.inputLocationTarget.value != ""){
      event.stopPropagation()
      event.preventDefault()
      this.warningMessageTarget.classList.toggle(this.messageClass)
    }else{
      this.warningMessageTarget.classList.add(this.messageClass)
    }
  }
}
