import LocalTime from "local-time"

LocalTime.config.i18n["fr"] = {
  date: {
    dayNames: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    abbrDayNames: [
      "Dim",
      "Lun",
      "Mar",
      "Mer",
      "Jeu",
      "Ven",
      "Sam"
    ],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    abbrMonthNames: [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Août",
      "Sep",
      "Oct",
      "Nov",
      "Déc"
    ],
    yesterday: "hier",
    today: "aujourd'hui",
    tomorrow: "demain",
    on: "{date}",
    formats: {
      default: "%e %b %Y",
      thisYear: "%e %b"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "une {time}",
    singularAn: "une {time}",
    elapsed: "il y a {time}",
    second: "seconde",
    seconds: "secondes",
    minute: "minute",
    minutes: "minutes",
    hour: "heure",
    hours: "heures",
    formats: {
      default: "%H:%M"
    }
  },
  datetime: {
    at: "{date} à {time}",
    formats: {
      default: "%e %B %Y à %H:%M %Z"
    }
  }
}

LocalTime.config.locale = "fr"
LocalTime.start()

