import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "no", "yes", "roleField" ]
  initialize(){
    this.selectedOptionClasses = (this.data.get('selected-class').split(' '))
  }

  connect(){
    if(this.data.get('selected-option') == "no"){
      this.no()
    }else{
      this.yes()
    }
  }

  yes() {
    this.yesTarget.classList.add(...this.selectedOptionClasses)
    this.noTarget.classList.remove(...this.selectedOptionClasses)
    this.roleFieldTarget.disabled = false
  }

  no() {
    this.noTarget.classList.add(...this.selectedOptionClasses)
    this.yesTarget.classList.remove(...this.selectedOptionClasses)
    this.roleFieldTarget.disabled = true
  }
}
