import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slide", "indicator" ]

  initialize() {
    this.showCurrentSlide()
  }

  next() {
    event.preventDefault()
    this.index++
  }

  previous() {
    event.preventDefault()
    this.index--
  }

  showSlide(event) {
    event.preventDefault()
    this.index = event.currentTarget.dataset.slideshowIndicatorIndex
  }

  lastSlide() {
    return (this.slideTargets.length - 1)
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("ob-slide--current", this.index == i)
    })
    this.showCurrentIndicator()
  }

  showCurrentIndicator() {
    this.indicatorTargets.forEach((el, i) => {
      el.classList.toggle("opacity-25", this.index != i)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    if (value >= this.slideTargets.length) {
      value = 0
    }

    if (value < 0) {
      value = this.lastSlide()
    }

    this.data.set("index", value)
    this.showCurrentSlide()
  }
}

