import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "trigger" ]

  show(event) {
    event.stopPropagation()
    const contentControlClass = this.data.get('control-class').split(' ')
    this.triggerTarget.classList.toggle('hidden')

    contentControlClass.forEach((klass, index) => {
      this.contentTarget.classList.toggle(klass)
    })
  }
}
