import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  hide(event) {
    event.stopPropagation()
    event.preventDefault()

    const noticeControlClass = this.itemTarget.getAttribute("data-control-class")
    this.itemTarget.classList.add(noticeControlClass)
  }
}
