import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  show(event) {
    event.stopPropagation()
    event.preventDefault()
    this.dropdownTarget.classList.toggle(this.data.get("class"));
  }

  hide(event) {
    if (event.target == this.element) {
      event.preventDefault()
      event.stopPropagation();
      return
    }

    if (!this.dropdownTarget.classList.contains(this.data.get("class"))) {
      this.dropdownTarget.classList.toggle(this.data.get("class"));
    }
  }
}
