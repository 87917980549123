//European Cookies consent
import "cookieconsent/build/cookieconsent.min.css"
import { CookieConsent } from 'cookieconsent';

window.addEventListener("turbo:load", function(){
  if (document.querySelector('meta[name="cookies_banner"]').content == "true") {
    cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#3c404d",
          "text": "#d6d6d6"
        },
        "button": {
          "background": "#8bed4f"
        }
      },
      "position": "bottom-right",
      "type": "opt-in",
      "showLink": false,
      "content": {
        "message": "En utilisant nos services, vous acceptez l'utilisation des cookies pour réaliser des statistiques de visites.",
        "deny": "Refuser",
        "dismiss": "Refuser",
        "allow": "Accepter",
        "link": "En savoir plus",
        "policy": "Vie privée"
      }
    })
  }
});
