import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "details", "buttonShow", "buttonHide" ]

  toggle(event) {
    event.stopPropagation()
    const detailsControlClass = this.detailsTarget.getAttribute("data-control-class")
    const buttonShowControlClass = this.buttonShowTarget.getAttribute("data-control-class")
    const buttonHideControlClass = this.buttonHideTarget.getAttribute("data-control-class")

    this.detailsTarget.classList.toggle(detailsControlClass)
    this.buttonShowTarget.classList.toggle(buttonShowControlClass)
    this.buttonHideTarget.classList.toggle(buttonHideControlClass)
  }
}
