document.addEventListener("turbo:load", () => {
  let button = document.querySelector('#email-alert-submit-button');
  if (button != null){
    button.onclick = function() {
      let alertForm = document.querySelector('#email-alert-form');
      var csrfToken = document.getElementsByName('csrf-token')[0].content;

      xhr = new XMLHttpRequest();
      xhr.open('POST', '/email_alerts');
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);

      var formData = new FormData();
      formData.append('name', alertForm.querySelector('[name="name"]').value);
      formData.append('user_email', alertForm.querySelector('[name="email"]').value);
      formData.append('filters', alertForm.querySelector('[name="filters"]').value);
      formData.append('alert_type', alertForm.querySelector('[name="alert_type"]').value);

      xhr.onload = function() {
        if (xhr.status === 200) {
          button.innerText = 'Alerte créée !';
          button.disabled = true;
          setTimeout(function() {
            alertForm.reset();
            button.innerText = "Créer l'alerte";
            button.disabled = false;
            document.querySelector('#email-alert-close-button').click();
          }, 500);
        }else{
          console.log(xhr.status);
        }
      };
      xhr.send(formData);

      return false;
    }
  }
});