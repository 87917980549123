import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "placeholder" ]
  connect(){
    if(navigator.share) {
      console.log("Found sharing capabilities, connecting share controller")
      this.placeholderTarget.classList.toggle(this.data.get("hidden-class"))
    }
  }
  share(event) {
    if(navigator.share) {
      navigator.share({
        title: `${this.data.get("title")}`,
        text: `${this.data.get("text")}`,
        url: `${this.data.get("url")}`
      })
        .then(() => console.log('Share complete'))
        .error((error) => console.error('Could not share at this time', error))
    }
  }
}
